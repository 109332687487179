import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';
import { is } from 'bpmn-js/lib/util/ModelUtil';
import { append as svgAppend, create as svgCreate } from 'tiny-svg';

export default function CustomRender(eventBus) {
  BaseRenderer.call(this, eventBus, 1500);

  this.getShapePath = BaseRenderer.prototype.getShapePath;
  this.drawConnection = BaseRenderer.prototype.drawConnection;
  this.getConnectionPath = BaseRenderer.prototype.getConnectionPath;

  
  this.getShapePath = function(shape) {
    if (shape.base64 || shape.url) {
      // Define o caminho como um retângulo
      return [
        ['M', shape.x, shape.y],
        ['l', shape.width, 0],
        ['l', 0, shape.height],
        ['l', -shape.width, 0],
        ['z']
      ];
    } else {
      // Use a lógica padrão de renderização
      return BaseRenderer.prototype.getShapePath.call(this, shape);
    }
  };

  this.canRender = function(element) {
    return is(element, 'bpmn:BaseElement');
    //return element.base64 || element.url || element.group === 'image';
  };

  this.drawShape = function(parent, shape) {

    var rect = svgCreate('rect', {
        x: -5,
        y: -5,
        width: shape.width + 10,
        height: shape.height + 10,
        fill: 'none',
        stroke: 'blue',
        'stroke-dasharray': '5,5', // Define o contorno pontilhado
        class: 'dotted-hover'
      });
    svgAppend(parent, rect);

    if (shape.base64 || shape.url) {
      var gfx = svgCreate('image', {
        x: 0,
        y: 0,
        width: shape.width,
        height: shape.height,
        href: shape.base64 || shape.url,
      });

      svgAppend(parent, gfx);

      return gfx;
    } else {
      // Chama a implementação padrão de drawShape
      return BaseRenderer.prototype.drawShape.call(this, parent, shape);
    }
  };

  this.drawConnection = function(parent, connection) {
  function calculateIntersection(p1, p2, p3, p4) {
    var denom = (p1.x - p2.x) * (p3.y - p4.y) - (p1.y - p2.y) * (p3.x - p4.x);
    if (denom === 0) return null; // Linhas paralelas

    var x = ((p1.x * p2.y - p1.y * p2.x) * (p3.x - p4.x) - (p1.x - p2.x) * (p3.x * p4.y - p3.y * p4.x)) / denom;
    var y = ((p1.x * p2.y - p1.y * p2.x) * (p3.y - p4.y) - (p1.y - p2.y) * (p3.x * p4.y - p3.y * p4.x)) / denom;

    return { x: x, y: y };
  }

  var buffer = 5; // Define o buffer de 5px
  var outerPoints = [];
  var innerPoints = [];

  connection.waypoints.forEach(function(waypoint, index) {
    var prevWaypoint = connection.waypoints[index - 1];
    var nextWaypoint = connection.waypoints[index + 1];

    var anglePrev = prevWaypoint ? Math.atan2(waypoint.y - prevWaypoint.y, waypoint.x - prevWaypoint.x) : null;
    var angleNext = nextWaypoint ? Math.atan2(nextWaypoint.y - waypoint.y, nextWaypoint.x - waypoint.x) : null;

    var offsetXPrev = anglePrev !== null ? buffer * Math.cos(anglePrev + Math.PI / 2) : 0;
    var offsetYPrev = anglePrev !== null ? buffer * Math.sin(anglePrev + Math.PI / 2) : 0;
    var offsetXNext = angleNext !== null ? buffer * Math.cos(angleNext + Math.PI / 2) : 0;
    var offsetYNext = angleNext !== null ? buffer * Math.sin(angleNext + Math.PI / 2) : 0;

    var outerPrev = { x: waypoint.x + offsetXPrev, y: waypoint.y + offsetYPrev };
    var innerPrev = { x: waypoint.x - offsetXPrev, y: waypoint.y - offsetYPrev };
    var outerNext = { x: waypoint.x + offsetXNext, y: waypoint.y + offsetYNext };
    var innerNext = { x: waypoint.x - offsetXNext, y: waypoint.y - offsetYNext };

    if (prevWaypoint && nextWaypoint) {
      var outerIntersection = calculateIntersection(prevWaypoint, outerPrev, nextWaypoint, outerNext);
      var innerIntersection = calculateIntersection(prevWaypoint, innerPrev, nextWaypoint, innerNext);

      if (outerIntersection) outerPoints.push(outerIntersection);
      if (innerIntersection) innerPoints.push(innerIntersection);
    } else {
      outerPoints.push(outerPrev);
      innerPoints.push(innerPrev);
      if (nextWaypoint) {
        outerPoints.push(outerNext);
        innerPoints.push(innerNext);
      }
    }
  });

  // Combine outer and inner points to form a continuous path with rounded corners
  var pathData = '';
  for (var i = 0; i < outerPoints.length; i++) {
    var point = outerPoints[i];
    if (i === 0) {
      pathData += `M${point.x},${point.y}`;
    } else {
      var prevPoint = outerPoints[i - 1];
      pathData += ` L${point.x},${point.y}`;
      if (i < outerPoints.length - 1) {
        var nextPoint = outerPoints[i + 1];
        var midPointX = (point.x + nextPoint.x) / 2;
        var midPointY = (point.y + nextPoint.y) / 2;
        pathData += ` Q${point.x},${point.y} ${midPointX},${midPointY}`;
      }
    }
  }

  for (var j = innerPoints.length - 1; j >= 0; j--) {
    var point = innerPoints[j];
    if (j === innerPoints.length - 1) {
      pathData += ` L${point.x},${point.y}`;
    } else {
      pathData += ` L${point.x},${point.y}`;
      if (j > 0) {
        var nextPoint = innerPoints[j - 1];
        var midPointX = (point.x + nextPoint.x) / 2;
        var midPointY = (point.y + nextPoint.y) / 2;
        pathData += ` Q${point.x},${point.y} ${midPointX},${midPointY}`;
      }
    }
  }

  pathData += ' Z'; // Fecha o caminho

  // Cria o polígono que contorna todos os waypoints com quinas arredondadas
  var path = svgCreate('path', {
    d: pathData,
    fill: 'none',
    stroke: 'red',
    'stroke-dasharray': '5,7', // Define o contorno pontilhado
    class: 'line-selected'
  });

  svgAppend(parent, path);
  
  return BaseRenderer.prototype.drawConnection.call(this, parent, connection);
};
}

CustomRender.$inject = [ 'eventBus' ];
