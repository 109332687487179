// This file is responsible for fetching the data from the server
const URL = window.location.hostname === 'localhost' ? 'http://localhost:3000/data' : '/data.json';

const getImageDataFromUrl = async (imageUrl) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const img = new Image();

    reader.onloadend = () => {
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        resolve({
          base64: reader.result,
          aspectRatio: aspectRatio
        });
      };
      img.onerror = reject;
      img.src = reader.result;
    };

    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const get = async () => {
  return fetch(URL).then(async (response) => {
    let data = await response.json();

    const imagePromises = data.images.map(async (image) => {
      const imageData = await getImageDataFromUrl(image.url);
      return {
        ...image,
        base64: imageData.base64,
        aspectRatio: imageData.aspectRatio
      };
    });

    try {
      data.images = await Promise.all(imagePromises);
    } catch (error) {
      console.error(error);
    } finally {
      document.getElementById('loading').style.display = 'none';
      return data;
    }

  });
};

export default get();