import function_array from "./functionArray";
import data from "./jsonData";

export default async function(event, modeler) {
  var eventBus = modeler.get('eventBus');

  const modeling = modeler.get('modeling');

  eventBus.on('element.contextmenu', (e) => {
    e.preventDefault();
    console.log(e);
  });

  Object.keys(function_array).forEach((eventType) => {
    eventBus.on('element.' + eventType, async (e) => {

      if (function_array[eventType][e.element.id]) {
        function_array[eventType][e.element.id](e, modeling, e.element);
      }
      else if (function_array[eventType][e.element.type]) {
        function_array[eventType][e.element.type](e, modeling, e.element);
      }

    });
  });

  let temp_data = data.json_object;
  if(temp_data.elements){
    const uniqueIds = [...new Set(temp_data.elements.map(element => element.id))];

    if(!uniqueIds.includes(event.element.id) && event.element.type !== 'label') {
      temp_data.elements.push({
        id: event.element.id,
        width: event.element.width,
        height: event.element.height,
        text: event.element.businessObject.name || '',
        url: event.element.url || '',
        fill: event.element.di.fill || '',
        stroke: event.element.di.stroke || ''
      });
    }
    else if (event.element.type === 'label') {
      temp_data.elements.forEach((element) => {
        if (element.id === event.element.labelTarget.id) {
          element.text = event.element.businessObject.name || '';
        }
      });
    }

    data.setData(temp_data);
  }

  const elements = modeler.get('elementRegistry').getAll();
  elements.forEach(element => {

    const gfx = modeler.get('elementRegistry').getGraphics(element);
    if (!gfx) return;

    const dottedHover = document.createElement('div');
    dottedHover.classList.add('dotted-hover');
    gfx.appendChild(dottedHover);
  });

}
