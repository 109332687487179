var data = {

    json_object: {},

    setData: (newData) => {
        newData.images.forEach(image => {
            if(image.base64) delete image.base64;
        });
        data.json_object = newData;
    },

}

export default data