import get from '../importer/index.js';

var function_array = {};
function_array.click = {};
function_array.dblclick = {};
function_array.contextmenu = {};

const json_data = await get;

Object.keys(json_data.palette).forEach((paletteElement) => {
    if (json_data.palette[paletteElement].enabled) {
        if (json_data.palette[paletteElement].events) 
            Object.keys(json_data.palette[paletteElement].events).forEach((eventType) => {
                function_array[eventType.split('.')[1]][json_data.palette[paletteElement].type] = new Function(
                    'e',
                    'modeling',
                    'diagramElement',
                    `
                    (async () => {
                        if (${json_data.palette[paletteElement].events[eventType] !== ''}) {
                            e.preventDefault(); 
                            let element = e.element;
                            function updateText(text) {
                                modeling.updateLabel(diagramElement, text);
                            }
                            function updateImage(image) {
                                e.element.url = image;
                                modeling.updateProperties(e.element, {});
                            }
                                ${json_data.palette[paletteElement].events[eventType]}; 
                        }
                    })();
                    `,
                );
            });
    }
    }
);

Object.keys(json_data.elements).forEach((element) => {
    if (json_data.elements[element].events) 
        Object.keys(json_data.elements[element].events).forEach((eventType) => {
            function_array[eventType.split('.')[1]][json_data.elements[element].id] = new Function(
                'e',
                'modeling',
                'diagramElement',
                `
                (async () => {
                    if (${json_data.elements[element].events[eventType] !== ''}) {
                        e.preventDefault(); 
                        let element = e.element;
                        function updateText(text) {
                            modeling.updateLabel(diagramElement, text);
                        }
                        function updateImage(image) {
                            e.element.url = image;
                        }
                        if (e.element.id === "${json_data.elements[element].id}") { 
                            ${json_data.elements[element].events[eventType]}; 
                            modeling.updateProperties(e.element, {});
                        } 
                    }
                })();
                `,
            );
        });
    }
);

export default function_array;
