import get from '../importer/index.js';
import data from '../utils/jsonData.js';

var function_array = {};
function_array.click = {};
function_array.dblclick = {};
function_array.contextmenu = {};

function_array.update = () => {
    if (data && data.json_object && data.json_object.palette)
        Object.keys(data.json_object.palette).forEach((paletteElement) => {
            if (data.json_object.palette[paletteElement].enabled) {
                if (data.json_object.palette[paletteElement].events)
                    Object.keys(data.json_object.palette[paletteElement].events).forEach((eventType) => {
                        function_array[eventType.split('.')[1]][data.json_object.palette[paletteElement].type] = new Function(
                            'e',
                            'modeling',
                            'diagramElement',
                            `
                            (async () => {
                                if (${data.json_object.palette[paletteElement].events[eventType] !== ''}) {
                                    e.preventDefault(); 
                                    let element = e.element;
                                    function updateText(text) {
                                        modeling.updateLabel(diagramElement, text);
                                    }
                                    function updateImage(image) {
                                        e.element.url = image;
                                        modeling.updateProperties(e.element, {});
                                    }
                                        ${data.json_object.palette[paletteElement].events[eventType]}; 
                                }
                            })();
                            `,
                        );
                    });
            }
        }
        );

    if (data.json_object.palette)
        Object.keys(data.json_object.elements).forEach((element) => {
            if (data.json_object.elements[element].events)
                Object.keys(data.json_object.elements[element].events).forEach((eventType) => {
                    function_array[eventType.split('.')[1]][data.json_object.elements[element].id] = new Function(
                        'e',
                        'modeling',
                        'diagramElement',
                        `
                        (async () => {
                            if (${data.json_object.elements[element].events[eventType] !== ''}) {
                                e.preventDefault(); 
                                let element = e.element;

                                function updateText(text) {
                                    modeling.updateLabel(diagramElement, text);
                                }

                                function changeColor(fillColor, strokeColor = '#000') {
                                    if (element) {
                                        modeling.setColor([element], {
                                          fill: fillColor,
                                          stroke: strokeColor,
                                        });
                                    }
                                }

                                function updateImage(image) {
                                    e.element.url = image;
                                }
                                if (e.element.id === "${data.json_object.elements[element].id}") { 
                                    ${data.json_object.elements[element].events[eventType]}; 
                                    modeling.updateProperties(e.element, {});
                                } 
                            }
                        })();
                        `,
                    );
                });
        }
        );
};

export default function_array;
