import data from './jsonData';

export default function applyJsonModifications(modeler, jsonData) {
  const elementRegistry = modeler.get('elementRegistry');
  const modeling = modeler.get('modeling');

  let imageStyle = '';

  jsonData.images.forEach((image) => {
    imageStyle += `
        .${image.title.replace(/\s+/g, '')} {
          background-image: url(${image.url});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 90%;
          height: 90%;
        }
      `;
  });


  let elements = document.querySelectorAll('.djs-palette .entry');

  elements.forEach((element) => {
    let entry = jsonData.palette[element.dataset.action];
    if (entry && (!entry.enabled )) {
      element.style.display = 'none';
    }
  });

  const style = document.createElement('style');
  style.innerHTML = imageStyle;
  document.head.appendChild(style);

  jsonData.elements.forEach((element) => {
    const diagramElement = elementRegistry.get(element.id);
    if (diagramElement) {

      // Define a cor
      modeling.setColor([ diagramElement ], {
        fill: element.fill,
        stroke: element.stroke,
      });

      if (element.url) diagramElement.url = element.url;

      // Define o texto
      if (element.text) modeling.updateLabel(diagramElement, element.text);

      diagramElement.width = element.width;
      diagramElement.height = element.height;

      if (diagramElement.label) {
        const labelPosition = {
          x: diagramElement.x + diagramElement.width / 2,
          y: diagramElement.y + diagramElement.height + 10,
          width: diagramElement.width / 3,
          height: 20,
        };
        modeling.updateLabel(diagramElement, element.text, labelPosition);
      }
    }
  });

  data.setData(jsonData);
}