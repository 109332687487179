import data from '../utils/jsonData';
import function_array from './functionArray';

export default function applyJsonModifications(modeler) {
  const elementRegistry = modeler.get('elementRegistry');
  const modeling = modeler.get('modeling');

  let imageStyle = '';

 if (data.json_object.images)
  data.json_object.images.forEach((image) => {
    imageStyle += `
        .${image.title.replace(/\s+/g, '')} {
          background-image: url(${image.url});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 90%;
          height: 90%;
        }
      `;
  });

  let elements = document.querySelectorAll('.djs-palette .entry');

  if (data.json_object.palette)
  elements.forEach((element) => {
    let entry = data.json_object.palette[element.dataset.action];
    if (entry && (!entry.enabled )) {
      element.style.display = 'none';
    }
  });

  const style = document.createElement('style');
  console.log(imageStyle);
  style.innerHTML = imageStyle;
  document.head.appendChild(style);

  if (data.json_object.elements)
  data.json_object.elements.forEach((element) => {
    const diagramElement = elementRegistry.get(element.id);
    if (diagramElement) {
  
      // Define a cor
      modeling.setColor([ diagramElement ], {
        fill: element.fill,
        stroke: element.stroke,
      });

      if (element.url) diagramElement.url = element.url;

      // Define o texto
      if (element.text) modeling.updateLabel(diagramElement, element.text);

      diagramElement.width = element.width;
      diagramElement.height = element.height;

      if (diagramElement.label && element.text) {
        const labelPosition = {
          x: diagramElement.x + diagramElement.width / 2,
          y: diagramElement.y + diagramElement.height + 10,
          width: diagramElement.width / 3,
          height: 20,
        };
        modeling.updateLabel(diagramElement, element.text, labelPosition);
      }
    }
  });

  // Adiciona elementos ao JSON se ainda não existirem
  elementRegistry.forEach((element) => {
    if (data.json_object.elements && element.type !== 'label' && !data.json_object.elements.find(e => e.id === element.id)) {
      data.json_object.elements.push({
        id: element.id,
        width: element.width,
        height: element.height,
        text: element.businessObject.name || '',
        url: element.url || '',
        fill: element.di.fill || '',
        stroke: element.di.stroke || ''
      });
    }
  });

  data.setData(data.json_object);
}