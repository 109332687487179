import function_array from "./functionArray";
import data from "./jsonData";

export default async function(event, modeler, jsonData) {
  var eventBus = modeler.get('eventBus');

  console.log(event.element);

  const modeling = modeler.get('modeling');

  eventBus.on('element.contextmenu', (e) => {
    e.preventDefault();
    console.log(e);
  });

  Object.keys(function_array).forEach((eventType) => {
    eventBus.on('element.' + eventType, async (e) => {

      if (function_array[eventType][e.element.id]) {
        function_array[eventType][e.element.id](e, modeling, e.element);
      }
      else if (function_array[eventType][e.element.type]) {
        function_array[eventType][e.element.type](e, modeling, e.element);
      }

    });
  });

  let temp_data = data.json_object;
  if(temp_data.elements){
    const uniqueIds = [...new Set(temp_data.elements.map(element => element.id))];

    if(!uniqueIds.includes(event.element.id) && event.element.type !== 'label') {
      temp_data.elements.push({
        id: event.element.id,
        width: event.element.width,
        text: event.element.businessObject.name,
        height: event.element.height,
      });
    }
    else if (event.element.type === 'label') {
      temp_data.elements.forEach((element) => {
        if (element.id === event.element.labelTarget.id) {
          element.text = event.element.labelTarget.businessObject.name;
        }
      });
    }

    data.setData(temp_data);
  }

}
