import { parseStringPromise } from 'xml2js'; // Certifique-se de instalar a biblioteca xml2js
import data from '../utils/jsonData'; // Importe o módulo jsonData
import bpmnJs from 'bpmn-js';

const fetchBpmnData = async (modeler) => {
  try {
    const { xml } = await modeler.saveXML({ format: true });
    if (!xml) {
      throw new Error('Erro ao salvar o XML do diagrama.');
    }

    const bpmnXml = await parseStringPromise(xml);
    let bpmnJson = null;

    if (bpmnXml['bpmn2:definitions'] && bpmnXml['bpmn2:definitions']['bpmn2:extensionElements']) {
      bpmnJson = bpmnXml['bpmn2:definitions']['bpmn2:extensionElements'][0]?.$.jsonData;
    }

    return bpmnJson;
  } catch (error) {
    console.log(error)
    let bpmnJson = (await fetch('http://localhost:3000/data')).text();
    return bpmnJson;
  }
};

const get = async (modeler) => {
  document.getElementById('loading').style.display = 'flex';

  let json_data = await fetchBpmnData(modeler);

  if (!json_data) {
    const response = await fetch('/resources/data.json');
    json_data = await response.text();
  }

  json_data = JSON.parse(json_data);

  await data.setData(json_data); // Aguarde a conclusão da função setData

  document.getElementById('loading').style.display = 'none';

  return;
};

export default get;