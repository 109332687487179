/* eslint-disable indent */

import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-js.css';

import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';

import './style.css';

import $, { event } from 'jquery';

import colorPicker from './color-picker/index.js';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import DraggingModule from 'diagram-js/lib/features/dragging';
import diagramXML from '../resources/newDiagram.bpmn';

import ResizeAllRules from './resize-all-rules/index.js';
import CustomPalette from './palette-provider/index.js';
import customRendererModule from './draw';
import get from './importer/index.js';

import shapeAdded from './utils/shapeAdded.js';
import data from './utils/jsonData.js';
import applyJsonModifications from './utils/applyJsonModifications.js';

var container = $('#js-drop-zone');
var exportArtifacts;

var modeler = new BpmnModeler({
  container: '#js-canvas',
  keyboard: {
    bindTo: window,
  },
  additionalModules: [DraggingModule, CustomPalette, ResizeAllRules, customRendererModule, , colorPicker],

  // bpmnRenderer: {
  //  defaultFillColor: '#1f1f1f',
  //  defaultStrokeColor: '#fff',
  // }, 
});

var eventBus = modeler.get('eventBus');
const customPalette = modeler.get('paletteProvider');
const paletteEntries = customPalette.getPaletteEntries();
const modeling = modeler.get('modeling');
const elementRegistry = modeler.get('elementRegistry');

var downloadLink = $('#js-download-diagram');
var downloadSvgLink = $('#js-download-svg');

modeler.get('eventBus').on('canvas.init', function () {
  const canvas = modeler.get('canvas')._container;

  canvas.style.touchAction = 'none'; // Previne o comportamento padrão do navegador
});

eventBus.on('shape.added', async (event) => {
  shapeAdded(event, modeler);

  // Adiciona o elemento ao JSON se ainda não existir
  const element = event.element;
  if (element.type !== 'label' && data.json_object.elements && !data.json_object.elements.find(e => e.id === element.id)) {
    data.json_object.elements.push({
      id: element.id,
      width: element.width,
      height: element.height,
      text: element.businessObject.name || '',
      url: element.url || '',
      fill: element.di.fill || '',
      stroke: element.di.stroke || ''
    });
    data.setData(data.json_object);
  } else if (element.type === 'label') {
    let parentElement = null;
    if (data.json_object.elements) parentElement = data.json_object.elements.find(e => e.id === element.labelTarget.id);
    if (parentElement) {
      parentElement.text = element.businessObject.name || '';
      data.setData(data.json_object);
    }
  }
});

eventBus.on('shape.remove', async (event) => {
  let temp_data = data.json_object;
  if (temp_data.elements) {
    temp_data.elements = temp_data.elements.filter((element) => element.id !== event.element.id);
    data.setData(temp_data);
  }
});

async function createNewDiagram() {
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    openDiagram(diagramXML);
  } else {
    const response = await fetch('./resources/newDiagram.bpmn');
    const diagram = await response.text();
    openDiagram(diagram).then(() => {
      const tempImage = data.json_object.images.map(image => {
        const { base64, aspectRatio, ...rest } = image;
        return rest;
      });

      let tempData = data.json_object;
      tempData.images = tempImage;

      document.getElementById('json-textarea').value = JSON.stringify(tempData, null, 2);
    });
  }
}

async function openDiagram(xml) {
  try {
    await modeler.importXML(xml);
    await get(modeler);
    container.removeClass('with-error').addClass('with-diagram');

    applyJsonModifications(modeler);

    addDottedHoverElements();
    elementRegistry.forEach(element => {
      modeling.updateProperties(element, {});
    });

    setEncoded(downloadLink, 'diagram.bpmn', xml);

  } catch (err) {
    container.removeClass('with-diagram').addClass('with-error');

    container.find('.error pre').text(err.message);

    console.error(err);
  }
}

function setEncoded(link, name, data) {
  var encodedData = encodeURIComponent(data);

  if (data) {
    link.addClass('active').attr({
      'href': 'data:application/bpmn20-xml;charset=UTF-8,' + encodedData,
      'download': name
    });
  } else {
    link.removeClass('active');
  }
}

async function fetchDiagramById(id) {
  try {
    const response = await fetch(`resources/getDiagram.php?id=${id}`);
    if (response.ok) {
      const xml = await response.text();
      openDiagram(xml);
    } else {
      console.error('Erro ao obter o diagrama:', response.statusText);
    }
  } catch (err) {
    console.error('Erro ao obter o diagrama:', err);
  }
}

function getIdFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('id');
}

function registerFileDrop(container, callback) {
  function handleFileSelect(e) {
    e.stopPropagation();
    e.preventDefault();

    var files = e.dataTransfer.files;
    var file = files[0];

    // Verificar se o arquivo existe e se é do tipo esperado (imagem ou BPMN)
    if (!file) return;

    if (file.type.startsWith('image/') || file.name.endsWith('.bpmn')) {
      var reader = new FileReader();

      reader.onload = async function (event) {
        if (file && file.type.startsWith('image/')) {
          const formData = new FormData();
          formData.append('type', 'image');
          formData.append('file', file);
          formData.append('title', file.name.split('.')[0]);

          const response = await fetch('resources/setData.php', {
            method: 'POST',
            body: formData,
          });

          if (response.ok) {
            const json = await response.json();
            const temp_json = data.json_object;
            temp_json.images.push(json);
            data.setData(temp_json);

            applyJsonModifications(modeler);

            // Enviar o BPMN atual após enviar a imagem
            const bpmnXml = await getCurrentDiagramXML();
            const bpmnFormData = new FormData();
            const bpmnFileName = `${new Date().toLocaleDateString('pt-BR').replace(/\//g, '')}_${file.name.split('.')[0]}.bpmn`;
            const bpmnBlob = new Blob([bpmnXml], { type: 'application/xml' });
            bpmnFormData.append('file', bpmnBlob, bpmnFileName);

            const bpmnResponse = await fetch('resources/setData.php', {
              method: 'POST',
              body: bpmnFormData,
            });

            if (!bpmnResponse.ok) {
              console.error('Erro ao enviar o BPMN atual');
            }
          } else {
            alert('Erro ao fazer upload da imagem');
          }
        } else if (file && file.name.endsWith('.bpmn')) {
          const xml = event.target.result;
          callback(xml);
        }
      };

      reader.readAsText(file);
    } else {
      // Ignorar caso não seja uma imagem ou arquivo BPMN
      console.log("Arquivo ignorado, não é um arquivo válido (imagem ou BPMN).");
    }
  }

  function handleDragOver(e) {
    e.stopPropagation();
    e.preventDefault();

    e.dataTransfer.dropEffect = 'copy'; // Explicitamente mostra que é uma cópia.
  }

  container.get(0).addEventListener('dragover', handleDragOver, false);
  container.get(0).addEventListener('drop', handleFileSelect, false);
}

document
  .getElementById('js-import-diagram')
  .addEventListener('click', function () {
    const fileInput = document.getElementById('js-file-input');
    fileInput.click(); // Abre a janela para escolher o arquivo

    fileInput.addEventListener(
      'change',
      function (event) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = function (e) {
            const xml = e.target.result;
            openDiagram(xml); // Chama a função para importar o diagrama
          };
          reader.readAsText(file);
        } else {
          alert('Por favor, selecione um arquivo BPMN válido.');
        }
      },
      { once: true },
    ); // Garante que o evento seja acionado apenas uma vez
  });

// file drag / drop ///////////////////////

// check file api availability
if (!window.FileList || !window.FileReader) {
  window.alert(
    'Looks like you use an older browser that does not support drag and drop. ' +
    'Try using Chrome, Firefox or the Internet Explorer > 10.',
  );
} else {
  registerFileDrop(container, openDiagram);
}

function addDottedHoverElements() {
  const elements = modeler.get('elementRegistry').getAll();
  elements.forEach(element => {
    const gfx = modeler.get('elementRegistry').getGraphics(element);
    if (!gfx) return;

    const dottedHover = document.createElement('div');
    dottedHover.classList.add('dotted-hover');
    gfx.appendChild(dottedHover);

    gfx.addEventListener('touchstart', (event) => {
      console.log('Elemento tocado:', element.id);
      event.preventDefault();
      event.stopPropagation();
      const clickEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      gfx.dispatchEvent(clickEvent);
    });

  });
}

const id = getIdFromUrl();
if (id) {
  fetchDiagramById(id);
} else {
  createNewDiagram();
}

$(function () {

  $('#js-create-diagram').click(function (e) {
    e.stopPropagation();
    e.preventDefault();

    createNewDiagram();
  });

  $('.buttons a').click(function (e) {
    if (!$(this).is('.active')) {
      e.preventDefault();
      e.stopPropagation();
    }
  });

  let removedElements = [];

  function removeDottedHoverElements() {
    const dottedHoverElements = document.querySelectorAll('.dotted-hover');
    const lineHoverElements = document.querySelectorAll('.line-selected');
    removedElements = [];
    dottedHoverElements.forEach(element => {
      removedElements.push({
        element: element.cloneNode(true),
        parent: element.parentNode
      });
      element.remove();
    });
    lineHoverElements.forEach(element => {
      removedElements.push({
        element: element.cloneNode(true),
        parent: element.parentNode
      });
      element.remove();
    });
  }

  function restoreDottedHoverElements() {
    removedElements.forEach(({ element, parent }) => {
      parent.appendChild(element);
    });
    removedElements = [];
  }

  exportArtifacts = async () => {
    try {
      removeDottedHoverElements();
      const { svg } = await modeler.saveSVG();
      setEncoded(downloadSvgLink, 'diagram.svg', svg);
    } catch (err) {
      console.error('Erro ao salvar SVG:', err);
      setEncoded(downloadSvgLink, 'diagram.svg', null);
    } finally {
      restoreDottedHoverElements();
    }

    try {
      const { xml } = await modeler.saveXML({ format: true }, function (err, xml) {
       if (!err) {
          downloadFile('diagrama-atualizado.bpmn', xml);
        }
      }); 
      let serializer = new XMLSerializer();
      const parser = new DOMParser();
      let xmlDoc = parser.parseFromString(xml, 'application/xml');
      
      let definitionsElement = xmlDoc.getElementsByTagName('bpmn2:definitions')[0];

      let extensionElements = definitionsElement.getElementsByTagName('bpmn2:extensionElements');

      if (!extensionElements.length) {
        extensionElements = xmlDoc.createElement('bpmn2:extensionElements');
        definitionsElement.appendChild(extensionElements);
      }


      let xmlJson = data.json_object;
      xmlJson.images = xmlJson.images.map(image => {
        const { base64, ...rest } = image;
        return rest;
      });
      
      let xmlString = JSON.stringify(xmlJson);

      extensionElements[0].setAttribute('jsonData', xmlString);

      setEncoded(downloadLink, 'diagram.bpmn', serializer.serializeToString(xmlDoc));

      return;
    } catch (err) {
      console.error('Erro ao salvar XML:', err);
      setEncoded(downloadLink, 'diagram.bpmn', null);
    }
  }


  modeler.on('commandStack.changed', exportArtifacts);
});

async function getCurrentDiagramXML() {
  try {
    const { xml } = await modeler.saveXML({ format: true });
    return xml;
  } catch (err) {
    console.error('Erro ao obter o XML do diagrama:', err);
  }
}

// helpers //////////////////////

function debounce(fn, timeout) {
  var timer;

  return function () {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(fn, timeout);
  };
}

const palette = document.querySelector('.djs-palette');

var zoomScroll = modeler.get('zoomScroll');

if (palette) {
  palette.addEventListener('mouseover', (e) => {
    zoomScroll.toggle(false);
  });
  palette.addEventListener('mouseout', (e) => {
    zoomScroll.toggle(true);
  });
}


function assignFunctionToElement(modeler, elementId, eventType, func) {

  const eventBus = modeler.get('eventBus');
  const target_element = elementRegistry.get(elementId);


  if (!target_element) {
    console.error(`Elemento com ID ${elementId} não encontrado.`);
    return;
  }

  function eventFunction(e, modeling, diagram_element) {
    e.preventDefault();
    let element = e.element;
    function updateText(text) {
      modeling.updateLabel(element, text);
    }
    function updateImage(image) {
      e.element.url = image;
      modeling.updateProperties(e.element, {});
    }
    if (e.element.id === diagram_element.id) {
      func();
    }
  }

  // Adiciona a função ao elemento
  eventBus.on(
    eventType,
    900,
    async (e) => eventFunction(e, modeling, target_element),
    { passive: true },
  );
}

function enableTouchSupport(modeler) {
  const canvas = modeler.get('canvas');
  const eventBus = modeler.get('eventBus');
  const dragging = modeler.get('dragging');
  const container = canvas._container;

  let isDragging = false;
  let startPosition = null;
  let initialDistance = null;

  container.addEventListener('touchstart', (event) => {
    if (event.touches.length === 1) {
      const touch = event.touches[0];
      startPosition = { x: touch.clientX, y: touch.clientY };
      isDragging = true;
      event.preventDefault();
    } else if (event.touches.length === 2) {
      initialDistance = getDistance(event.touches);
      isDragging = false;
      event.preventDefault();
    }
  });

  container.addEventListener('touchmove', (event) => {
    if (isDragging && event.touches.length === 1) {
      const touch = event.touches[0];
      const movePosition = { x: touch.clientX, y: touch.clientY };

      const dx = movePosition.x - startPosition.x;
      const dy = movePosition.y - startPosition.y;

      const viewbox = canvas.viewbox();
      canvas.viewbox({
        x: viewbox.x - dx / viewbox.scale,
        y: viewbox.y - dy / viewbox.scale,
        width: viewbox.width,
        height: viewbox.height,
      });

      startPosition = movePosition;
      event.preventDefault();
    } else if (event.touches.length === 2) {
      const newDistance = getDistance(event.touches);
      const scaleChange = newDistance / initialDistance;

      const viewbox = canvas.viewbox();
      canvas.zoom(viewbox.scale * scaleChange, 'auto');

      initialDistance = newDistance;
      event.preventDefault();
    }
  });

  container.addEventListener('touchend', (event) => {
    if (isDragging) {
      isDragging = false;
      event.preventDefault();
    }
  });

  function getDistance(touches) {
    const [touch1, touch2] = touches;
    const dx = touch2.clientX - touch1.clientX;
    const dy = touch2.clientY - touch1.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  }
}


function changeColor(elementID, fillColor, strokeColor = '#000') {
  const element = elementRegistry.get(elementID);
  if (element) {
    modeling.setColor([element], {
      fill: fillColor,
      stroke: strokeColor,
    });

    // Atualiza o JSON com as novas cores
    const jsonElement = data.json_object.elements.find(e => e.id === element.id);
    if (jsonElement) {
      jsonElement.fill = fillColor;
      jsonElement.stroke = strokeColor;
    }
  }
}

window.modeler = modeler;
window.assignFunctionToElement = assignFunctionToElement;
window.changeColor = changeColor;


// Modo de edição e visualização
let isEditMode = true;
const dragging = modeler.get('dragging');


function toggleMode() {
  isEditMode = !isEditMode;

  const palette = document.querySelector('.djs-palette');
  const resizableElements = document.querySelector('.layer-resizers');
  const editableElements = document.querySelectorAll('.djs-editable');
  const elements = document.querySelectorAll('.djs-hit')
  const pad = document.querySelector('.djs-context-pad-parent');

  if (isEditMode) {
    // Ativar modo de edição
    if (palette) palette.style.display = 'block';
    resizableElements.style.display = 'block';
    editableElements.forEach(el => el.contentEditable = 'true');
    pad.style.display = 'block';
    dragging.setOptions({ manual: false });

  } else {
    // Ativar modo de visualização
    if (palette) palette.style.display = 'none';
    resizableElements.style.display = 'none';
    editableElements.forEach(el => el.contentEditable = 'false');
    pad.style.display = 'none';

    dragging.setOptions({ manual: true });
  }
}


document.getElementById('toggle-mode').addEventListener('click', toggleMode);

// filter

if (palette) {
  let filter = document.createElement('select');
  let families = [];

  filter.style.width = '90%';
  filter.style.padding = '5px';
  filter.style.height = '30px';
  filter.style.borderRadius = '8px';
  filter.style.marginBottom = '10px';
  filter.style.margin = '5px';
  filter.classList.add('filter');

  let option = document.createElement('option');
  option.value = 'none';
  option.text = 'Filtrar';
  filter.appendChild(option);

  if (data.json_object.images)
    data.json_object.images.forEach((image) => {
      if (image.family && !families.includes(image.family)) {
        families.push(image.family);
        let option = document.createElement('option');
        option.value = image.family;
        option.text = image.family;
        filter.appendChild(option);
      }
    });

  Object.keys(paletteEntries).forEach((element) => {
    if (data.json_object.palette && data.json_object.palette[element] && !data.json_object.palette[element].enabled) {
      paletteEntries[element].enabled = false;
    } else if (data.json_object.palette && data.json_object.palette[element])
      paletteEntries[element].enabled = true;
  });

  filter.addEventListener('change', (e) => {
    let value = e.target.value;
    let elements = document.querySelectorAll('.djs-palette .entry');

    elements.forEach((element) => {
      let entry = paletteEntries[element.dataset.action];
      if (entry.group === 'image') {
        if ((value === 'none') || (entry && entry.family === value)) {
          if (entry.enabled) element.style.display = 'block';
        } else {
          element.style.display = 'none';
        }
      }
    });
  });

  palette.insertBefore(filter, palette.firstChild);
}

const jsonEditor = document.getElementsByClassName('json-editor')[0];
const resizer = jsonEditor.querySelector('.resizer');
jsonEditor.style.display = 'none';
var editor;

document.addEventListener('keydown', (e) => {
  if (e.key === 'F1') {
    if (jsonEditor.style.display === 'none') {
      jsonEditor.style.display = 'flex';

      setTimeout(() => {
        jsonEditor.style.transform = 'translateX(0)';
      }, 10); // Delay para que a transição aconteça

      const tempImage = data.json_object.images.map(image => {
        const { base64, aspectRatio, ...rest } = image;
        return rest;
      });

      let tempData = data.json_object;
      tempData.images = tempImage;

      document.getElementById('json-textarea').value = JSON.stringify(tempData, null, 2);

      if (!jsonEditor.codeMirrorInitialized) {
        editor = CodeMirror.fromTextArea(document.getElementById('json-textarea'), {
          mode: { name: "javascript", json: true },
          theme: "material",
          lineNumbers: true, // Exibe os números das linhas
          lineWrapping: true, // Ativa a quebra automática de linha
          viewportMargin: Infinity
        });
        jsonEditor.codeMirrorInitialized = true;

        let codeMirror = document.getElementsByClassName('CodeMirror')[0];

        codeMirror.style.width = jsonEditor.style.width;
      }

    }
    else {

      jsonEditor.style.transform = 'translateX(100%)';
      // Espera o tempo da animação antes de esconder
      setTimeout(() => {
        jsonEditor.style.display = 'none';
      }, 300); // Espera o tempo da animação (300m
    }
  }
});


let isResizing = false;
let startX = 0; // Ponto inicial ao iniciar o redimensionamento
let startWidth = 0; // Largura inicial ao iniciar o redimensionamento

resizer.addEventListener('mousedown', (e) => {
  isResizing = true;
  startX = e.clientX; // Captura a posição inicial do mouse
  startWidth = jsonEditor.offsetWidth; // Captura a largura inicial da div
  document.body.style.cursor = 'ew-resize';
  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);
  console.log("resize");
});

function onMouseMove(e) {
  if (!isResizing) return;

  let codeMirror = document.getElementsByClassName('CodeMirror')[0];

  // Calcula o delta acumulado em relação ao ponto inicial
  const deltaX = startX - e.clientX;
  const newWidth = startWidth + deltaX;

  // Define a nova largura, garantindo um mínimo
  if (newWidth > 100) { // Limite mínimo para evitar colapso
    jsonEditor.style.width = `${newWidth}px`;
    codeMirror.style.width = `${newWidth - 10}px`;
    jsonEditor.style.right = '0'; // Mantém a posição no lado direito
  }
}

function onMouseUp() {
  if (isResizing) {
    isResizing = false;
    document.body.style.cursor = 'default';
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  }
}


document.getElementById('save').addEventListener('click', async () => {
  const json = editor.getValue();
  try {
    const textAreaJson = JSON.parse(json);
    await data.setData(textAreaJson).then(() => {
      applyJsonModifications(modeler);
      exportArtifacts();
      alert('Dados salvos com sucesso!');
    });

  } catch (err) {
    alert('Erro ao salvar os dados. Verifique o formato do JSON.');
    console.log(err);
  }
});

window.addEventListener('DOMContentLoaded', () => {
  document.getElementsByClassName('buttons')[0].style.display = 'none';
});

enableTouchSupport(modeler);

export default modeler;

