import modeler from '../app';
import function_array from './functionArray';

var data = {
    json_object: {},
  
    setData: async (newData) => {

     /*  console.log("newData", newData); */

      if (newData.images) {
        const imagePromises = newData.images.map(image => {
          if (!image.base64) {
            return fetch(image.url)
              .then(response => response.blob())
              .then(blob => {
                return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    image.base64 = reader.result;
                    resolve();
                  };
                  reader.onerror = reject;
                  reader.readAsDataURL(blob);
                });
              })
              .catch(error => {
                console.error('Error fetching image:', error);
              });
          }
        });
  
        await Promise.all(imagePromises);
      }
  
      // Adicionar a propriedade url aos elementos
      if (newData.elements) {
        newData.elements.forEach(element => {
          const image = newData.images.find(img => img.id === element.id);
          if (image) {
            element.url = image.url;
          }
        });
      }
  
      data.json_object = newData;

      const customPalette = modeler.get('paletteProvider');
      function_array.update();
      customPalette.updatePalette();

    },
  };
  
  export default data;