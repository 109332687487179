import inherits from 'inherits-browser';

import PaletteProvider from 'bpmn-js/lib/features/palette/PaletteProvider';

import data from '../utils/jsonData';

import get from '../importer/index';


function getImageSize(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = url;
  });
}

export default function CustomPaletteProvider(
  palette,
  create,
  elementFactory,
  spaceTool,
  lassoTool,
  handTool,
  globalConnect,
  translate,
) {
  PaletteProvider.call(
    this,
    palette,
    create,
    elementFactory,
    spaceTool,
    lassoTool,
    handTool,
    globalConnect,
    translate,
  );

  this._create = create;
  this._elementFactory = elementFactory;
  this.translate = translate;
}

inherits(CustomPaletteProvider, PaletteProvider);

CustomPaletteProvider.$inject = [
  'palette',
  'create',
  'elementFactory',
  'spaceTool',
  'lassoTool',
  'handTool',
  'globalConnect',
  'translate',
];

CustomPaletteProvider.prototype.updatePalette = function() {
  const palette = this._palette;
  const actions = this.getPaletteEntries();

  palette._entries = actions;
  palette._update();
};

CustomPaletteProvider.prototype.getPaletteEntries = function (element) {

  var actions = PaletteProvider.prototype.getPaletteEntries.call(this, element);
  var create = this._create;
  var elementFactory = this._elementFactory;

  function createAction(type, group, title, base64, url, initialHeight, initialWidth, options = {}, enabled, family = null) {
    async function createListener(event) {

      let height = 150;

      if (title !== 'Link Event') {
        const size = await getImageSize(url);
        const aspectRatio = size.width / size.height;
        height = initialWidth/aspectRatio;
      } else {
        height = initialHeight
      }

      var shape = elementFactory.createShape(
        Object.assign({ type: type, title: title, base64: base64, url: url, group: group, width: initialWidth, height: height, init: true }, options),
      );
      create.start(event, shape);
    }

    return {
      group: group,
      className: title.replace(/\s+/g, ''),
      title: title,
      action: {
        dragstart: createListener,
        click: createListener,
      },
      'enabled': enabled,
      'family': family
    };
  }

  function createSeparator() {
    return {
      group: 'separator',
      separator: true
    };
  }

  actions['separator'] = createSeparator();

  if(data.json_object.images)
  data.json_object.images.forEach((element) => {
    actions[`create.custom:${element.title}`] = createAction(
      'bpmn:IntermediateThrowEvent',
      'image',
      element.title,
      element.base64,
      element.url,
      null,
      element.initialWidth || 150,
      {},
      element.enabled,
      element.family
    );
  });

  // Adiciona um separador antes dos eventos
  actions['create.link'] = createAction(
    'bpmn:DataObjectReference',
    'event',
    'Link Event',
    '/static/link.png',
    '',
    54,
    54,
    { link: true },
    true
  );

  return actions;
};
